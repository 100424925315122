import React from 'react';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import s from './Collections.module.scss';

interface HeaderLinkProps {
  to: string;
  title: string;
  disabled: boolean;
}

const HeaderLink: React.FC<HeaderLinkProps> = ({ to, title, disabled }) => (
  <Typography variant="h6" className={disabled ? s.disableItemMenu : ''}>
    <NavLink to={to} className={({ isActive }) => (isActive ? s.active : '')}>
      {title}
    </NavLink>
  </Typography>
);

export default HeaderLink;
