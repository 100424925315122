import React from 'react';
import { IconButton, InputAdornment, FilledInput } from '@mui/material';
import Clear from '@mui/icons-material/Clear';

interface SearchFieldProps {
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
}

/**
 * Search field
 *
 * @param {string} searchValue - search value
 * @param {(searchValue: string) => void} setSearchValue -
 * callback function to set a value
 */
const SearchField = ({ searchValue, setSearchValue }: SearchFieldProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(event.target.value);

  const clear = () => setSearchValue('');

  return (
    <FilledInput
      size="small"
      placeholder="Search by Collection's symbol and name"
      value={searchValue}
      onChange={handleChange}
      sx={{ input: { pt: 1, pb: 1.5 }, width: 365, p: 0 }}
      endAdornment={
        searchValue.length > 0 && (
          <InputAdornment position="end">
            <IconButton onClick={clear}>
              <Clear />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};

export default SearchField;
