import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Box, CircularProgress, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Stores/RootStore';
import MyCollectionCard from '../MyCollectionCard/MyCollectionCard';
import { configNetList } from '../../configNetList';
import HeaderLink from './HeaderLink';
import { RemoteDataState } from '../../Utils/RemoteData';
import CustomContainer from '../../Components/CustomContainer/CustomContainer';
import { MetamaskState } from '../../Stores/MetaMaskStore';
import SearchField from '../../Components/SearchField/SearchField';
import s from './Collections.module.scss';

const ENV_APP = window.config.ENV_APP as 'test' | 'prod';

const defaultDisplayCollectionsLimit = 12;

const Collections = () => {
  const { t } = useTranslation();
  const { metaMaskStore, collectionsStore } = useStores();
  const { net } = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [isNavigateToTab, setNavigateToTab] = useState(false);
  const [collections, setCollections] = useState<CollectionTypeTg[]>([]);
  const [limitDisplayCollections, setLimitDisplayCollections] = useState(
    defaultDisplayCollectionsLimit
  );

  const setChain = (name: string) => {
    const chainData = configNetList[ENV_APP].find(i => i.nameForUrl === name);
    if (!chainData) {
      navigate(`/showroom/${configNetList[ENV_APP][0].nameForUrl}`);
      return;
    }
    if (metaMaskStore.readOnly) {
      collectionsStore.setChain(chainData);
    } else {
      metaMaskStore.setChainId(chainData.id);
    }
  };

  useEffect(() => {
    if (metaMaskStore.state === MetamaskState.Initial) return;
    if (!metaMaskStore.readOnly && net) navigate('/showroom');
    if (!metaMaskStore.readOnly) return;
    if (net) {
      setChain(net);
    } else {
      navigate(`/showroom/${configNetList[ENV_APP][0].nameForUrl}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [net, metaMaskStore.state, metaMaskStore.readOnly]);

  useEffect(() => {
    if (collectionsStore.chainData) {
      setCollections([]);
      setNavigateToTab(true);
      collectionsStore.getAllCollections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionsStore.chainData?.id]);

  const allCollections = useMemo(
    () =>
      collectionsStore.allCollections.value?.filter(i => i.tokens.length > 0) ??
      [],
    [collectionsStore.allCollections.value]
  );

  useEffect(() => {
    if (
      isNavigateToTab &&
      collectionsStore.allCollections.state !== RemoteDataState.REQUEST
    ) {
      setSearch('');
      setNavigateToTab(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionsStore.allCollections.state]);

  useEffect(() => {
    if (allCollections.length > 0) {
      const pattern = search.trim().toLowerCase();
      setCollections(
        allCollections.filter(
          i =>
            i.name.toLowerCase().includes(pattern) ||
            i.symbol.toLowerCase().includes(pattern)
        )
      );
    } else {
      setCollections([]);
    }
  }, [allCollections, search]);

  const showMore = () => {
    setLimitDisplayCollections(prev => prev + defaultDisplayCollectionsLimit);
  };

  const path = useMemo(
    () => `/showroom/${collectionsStore.chainData?.nameForUrl}`,
    [collectionsStore.chainData?.nameForUrl]
  );

  if (metaMaskStore.state === MetamaskState.Initial) {
    return (
      <Box
        sx={{
          height: 'calc(100vh - 90px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress size={32} />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <CustomContainer className={s.main}>
        {metaMaskStore.readOnly && (
          <Box className={s.topMenu}>
            {configNetList[ENV_APP].map(i => (
              <HeaderLink
                to={`/showroom/${i.nameForUrl}`}
                title={i.name}
                key={i.id}
                disabled={
                  collectionsStore.allCollections.state ===
                  RemoteDataState.REQUEST
                }
              />
            ))}
          </Box>
        )}
        <Typography variant="h1">{t('collections')}</Typography>

        {metaMaskStore.readOnly ? (
          <>
            {!!allCollections.length && !isNavigateToTab && (
              <SearchField searchValue={search} setSearchValue={setSearch} />
            )}

            {isNavigateToTab && (
              <Box mt={2} mb={2}>
                <CircularProgress size={32} />
              </Box>
            )}
          </>
        ) : (
          <>
            {collectionsStore.allCollections.state ===
              RemoteDataState.REQUEST &&
              !allCollections.length && (
                <Box mt={2} mb={2}>
                  <CircularProgress size={32} />
                </Box>
              )}
            {!!allCollections.length && (
              <SearchField searchValue={search} setSearchValue={setSearch} />
            )}
          </>
        )}

        {collectionsStore.allCollections.state !== RemoteDataState.REQUEST &&
          !allCollections.length && (
            <Typography mt={2} variant="h2">
              {t('no_collections')}
            </Typography>
          )}

        <Box mt={4} className={s.list}>
          {collections.map(
            (i, index) =>
              index < limitDisplayCollections && (
                <MyCollectionCard
                  collection={i}
                  path={path}
                  showroom={true}
                  key={i.id}
                />
              )
          )}
        </Box>

        {collectionsStore.allCollections.state !== RemoteDataState.REQUEST &&
          collections.length > limitDisplayCollections && (
            <Button variant="contained" onClick={showMore} sx={{ mt: 3 }}>
              Show more
            </Button>
          )}
      </CustomContainer>
    </React.Fragment>
  );
};

export default observer(Collections);
