export const MAXIMUM_FILE_SIZE = 104_857_600;
export const FILE_SIZE_20M = 20_971_520;
export const IMAGE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/svg+xml',
  'image/gif'
];
export const VIDEO_TYPES = ['video/mp4', 'video/webm'];
export const AUDIO_TYPES = ['audio/wav', 'audio/ogg', 'audio/mpeg'];
export const THREED_TYPES = ['glb', 'gltf'];

export const TOKENS = ['ERC721'];

export const STORAGE_PENDING_TRANSACTIONS = 'pendingTransactions';

// update intervals
export const UPDATE_INTERVAL = 60000;

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
