import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress, Box } from '@mui/material';
import { oc } from 'ts-optchain';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../../Stores/RootStore';
import { observer } from 'mobx-react-lite';
import { RemoteDataState } from '../../Utils/RemoteData';
import Token from '../../Components/Token/Token';
import { configNetList } from '../../configNetList';
import { MetamaskState } from '../../Stores/MetaMaskStore';
import { t } from 'i18next';

const ENV_APP = window.config.ENV_APP as 'test' | 'prod';

const DetailNft = ({ showroom = false }) => {
  const { collectionId, id, net } = useParams();
  const { metaMaskStore, collectionsStore } = useStores();
  const { baseSiteURI, getCollectionById, getTokenById } = collectionsStore;
  const { config, chainData } = metaMaskStore;
  const navigate = useNavigate();
  let nft: Nullable<TokenTypeTg> = collectionsStore.token.value;

  useEffect(() => {
    if (!net) return;
    const chainData = configNetList[ENV_APP].find(i => i.nameForUrl === net);
    if (!chainData) return;
    if (metaMaskStore.readOnly) {
      collectionsStore.setChain(chainData);
    } else {
      metaMaskStore.setChainId(chainData.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (collectionsStore.chainData?.tgUriMintMe && showroom) {
      getTokenById(`${id}-${collectionId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionsStore.chainData?.tgUriMintMe, showroom]);

  useEffect(() => {
    if (
      metaMaskStore.state === MetamaskState.NotInstalled ||
      metaMaskStore.state === MetamaskState.Installed
    ) {
      const pathname = window.location.pathname;
      if (pathname.indexOf('showroom') === -1) {
        navigate(`/showroom${pathname}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaMaskStore.state]);

  if (metaMaskStore.state === MetamaskState.Initial) {
    return <CircularProgress size={32} />;
  }

  if (!collectionId || !id || !collectionsStore.chainData?.tgUriMintMe) {
    return <CircularProgress size={32} />;
  }

  const collection = getCollectionById(
    collectionsStore.allUserCollections,
    collectionId
  );

  if (collection && !showroom) {
    nft = collection.tokens.find(i => i.tokenId === id);
  }

  if (!nft && collectionsStore.token.state === RemoteDataState.REQUEST) {
    return (
      <Box
        sx={{
          height: 'calc(100vh - 90px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress size={32} />
      </Box>
    );
  }
  if (!nft) return <Typography variant="h1">{t('token_not_found')}</Typography>;

  const verifyData = oc(collectionsStore)
    .tokensVerifyDataTg.value([])
    .find((i: TokenVerifyDataTgType) => i.tokenId === nft?.id);

  return (
    <React.Fragment>
      <Token
        collectionId={collectionId}
        id={id}
        status={oc(verifyData).status(null)}
        nft={nft}
        licenses={oc(config).value.licenses([])}
        baseURI={baseSiteURI}
        chainData={chainData}
        readonly={
          nft.owner !== metaMaskStore.currentAccount || metaMaskStore.readOnly
        }
        // path={net ? `/showroom/${net}` : '/showroom'}
        path={showroom ? `/showroom/${net}` : `/${net}`}
      />
    </React.Fragment>
  );
};

export default observer(DetailNft);
