import React from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Formik, Form, Field } from 'formik';
import { oc } from 'ts-optchain';
import { TextField } from 'formik-mui';
import { v4 } from 'uuid';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { uploadJson, uploadImage } from '../../../Utils/Storage';
import { useStores } from '../../../Stores/RootStore';
import FieldLabel from '../../../Components/FieldLabel/FieldLabel';
import arrowLeft from '../../../Resources/ArrowLeft.svg';
import ButtonLink from '../../../Components/ButtonLink/ButtonLink';
import photoImg from '../../../Resources/photo.svg';
import verifiedIconGreen from '../../../Resources/verified_green.svg';
import verifiedIconGrey from '../../../Resources/verified_grey.svg';
import verifiedIconRed from '../../../Resources/verified_red.svg';
import { handleFileMedia } from '../../../Utils/HandleFile';
import AppCardMedia from '../../../Components/AppCardMedia/AppCardMedia';
import ReportVerifyProver from '../../ReportVerifyProver/ReportVerifyProver';
import schema from './schema';
import { getCidFromUri } from '../../../Helpers/helpers';
import { base16To32 } from '../../../Utils/Storage';
import { isVideoFile } from '../../../Utils/HandleFile';
import {
  EditTokenSuccessDialog,
  EditTokenErrorDialog
} from '../../../Components/Dialogs/Dialogs';
import { RemoteDataState } from '../../../Utils/RemoteData';
import s from '../Forms.module.scss';
import {
  IMAGE_TYPES,
  VIDEO_TYPES,
  AUDIO_TYPES,
  THREED_TYPES,
  FILE_SIZE_20M
} from '../../../constants';
import CustomContainer from '../../../Components/CustomContainer/CustomContainer';

const CreateNft = () => {
  const { t } = useTranslation();
  const { collectionId, id } = useParams();
  const { metaMaskStore, collectionsStore } = useStores();
  const provider = oc(metaMaskStore).provider(null);
  const { getCollectionById, baseURI, baseSiteURI, siteURI } = collectionsStore;
  const netName = metaMaskStore.chainData?.nameForUrl;

  if (!provider || !id || !collectionId || !netName) return null;

  const collection = getCollectionById(
    collectionsStore.allUserCollections,
    collectionId
  );

  if (!collection) return null;

  const nft = collection.tokens.find(i => i.tokenId === id);
  if (!nft) return null;

  const verifyData = oc(collectionsStore)
    .tokensVerifyDataTg.value([])
    .find((i: TokenVerifyDataTgType) => i.tokenId === nft?.id);
  const status = verifyData?.status;

  const handleVerifyByProver = async () => {
    if (
      provider &&
      nft.content &&
      metaMaskStore.configProveMe.value?.contract
    ) {
      collectionsStore.verifyByProver(
        nft,
        provider,
        metaMaskStore.configProveMe.value.contract
      );
    }
  };

  const feeVerifyByProverStr = collectionsStore.feeVerifyByProverStr;

  return (
    <React.Fragment>
      <CustomContainer className={s.main}>
        <Formik
          initialValues={{
            symbol: '',
            name: nft.name,
            description: nft.description,
            mediaFile: {
              file: null,
              name: '',
              path: '',
              error: ''
            },
            cover: {
              file: null,
              name: '',
              path: '',
              error: ''
            }
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const mediaUri = values.mediaFile.file
              ? baseURI.concat(await uploadImage(values.mediaFile.file))
              : base16To32(nft.content);
            const coverCid = values.cover.file
              ? baseURI.concat(await uploadImage(values.cover.file))
              : base16To32(nft.image);

            let externalUrl = nft.external_url;
            if (
              // correction of the old format /uuid... If the format is old then, create a new external_url
              /uuid/i.test(externalUrl) ||
              // checking collection. If the collection does not match, then create a new external_url
              !new RegExp(`${collectionId}`, 'i').test(externalUrl)
            ) {
              externalUrl = `${siteURI}/token/${netName}/${collectionId}/${v4()}`;
            }

            const { name, description } = values;
            const image = coverCid;
            const external_url = externalUrl;
            const content = mediaUri;
            const animation_url = isVideoFile(values.mediaFile.file)
              ? content
              : ''; // duplicate content if content is animation
            const contentCid = await uploadJson({
              name,
              description,
              image,
              external_url,
              animation_url,
              content
            });
            if (contentCid) {
              collectionsStore.setContentToken(
                id,
                contentCid,
                provider,
                collectionId
              );
            }
            setSubmitting(false);
          }}
          validationSchema={schema}
        >
          {({ submitForm, isSubmitting, setFieldValue, values }) => (
            <Form>
              <Typography variant="h1">
                <RouterLink
                  to={`/${metaMaskStore.chainData?.nameForUrl}/collection/${collectionId}`}
                >
                  <img src={arrowLeft} alt="" />
                </RouterLink>{' '}
                {nft.name}
              </Typography>
              <Box mt={4} className={s.mainForm}>
                <Box>
                  <Box>
                    <FieldLabel title={t('image_video_audio')} mb={0} />
                    <Typography variant="caption">
                      {t('file_types_supported_media_file')}
                    </Typography>
                    <input
                      accept={`
                        ${IMAGE_TYPES},
                        ${VIDEO_TYPES},
                        ${AUDIO_TYPES},
                        ${THREED_TYPES.map(item => {
                          return '.' + item;
                        })}
                      `}
                      className={s.uploadInput}
                      id="mediaFile"
                      type="file"
                      name="mediaFile"
                      onChange={e =>
                        setFieldValue(
                          'mediaFile',
                          handleFileMedia(e) ?? values.mediaFile
                        )
                      }
                    />
                    <Box width="190px" my={1} className={s.boxImage}>
                      <label htmlFor="mediaFile">
                        <AppCardMedia
                          mediaObj={values.mediaFile}
                          path={baseSiteURI.concat(getCidFromUri(nft.content))}
                          sx={{ objectFit: 'contain' }}
                        />
                      </label>
                    </Box>
                  </Box>
                  <Box mt={3}>
                    <FieldLabel title={t('cover')} mb={0} />
                    <Typography variant="caption">
                      {t('file_types_supported_cover')}
                    </Typography>
                    <input
                      accept={`${IMAGE_TYPES}, ${VIDEO_TYPES}`}
                      className={s.uploadInput}
                      id="uploadarea"
                      type="file"
                      name="cover"
                      onChange={e =>
                        setFieldValue(
                          'cover',
                          handleFileMedia(e, FILE_SIZE_20M) ?? values.cover
                        )
                      }
                    />
                    <Box width="190px" my={1} className={s.boxImage}>
                      <label htmlFor="uploadarea">
                        <AppCardMedia
                          mediaObj={values.cover}
                          path={
                            nft.image
                              ? baseSiteURI.concat(getCidFromUri(nft.image))
                              : photoImg
                          }
                          sx={{ objectFit: 'contain' }}
                        />
                      </label>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box mb={3}>
                    <FieldLabel title={t('name')} />
                    <Field
                      component={TextField}
                      name="name"
                      type="text"
                      hiddenLabel
                      variant="filled"
                      size="small"
                      className={s.inputField}
                    />
                  </Box>
                  <Box mb={3}>
                    <FieldLabel title={t('description')} />
                    <Field
                      component={TextField}
                      name="description"
                      type="text"
                      hiddenLabel
                      multiline
                      rows={4}
                      variant="filled"
                      size="small"
                      className={cx(s.inputField, s.multiLine)}
                    />
                  </Box>
                  <Box mt={5} className={s.boxVerification}>
                    {status === null &&
                      collectionsStore.tokensVerifyDataTg.state ===
                        RemoteDataState.REQUEST && (
                        <>
                          <CircularProgress size={24} /> <Box />
                        </>
                      )}
                    {status === 'Initial' && (
                      <ButtonLink
                        to=""
                        title={t('verify_by_prover_value', {
                          value: feeVerifyByProverStr,
                          currency:
                            metaMaskStore.chainData?.currency.toUpperCase()
                        })}
                        fullWidth={true}
                        onClick={handleVerifyByProver}
                      />
                    )}
                    {status === 'Pending' && (
                      <Box className={s.flexStart}>
                        <img src={verifiedIconGrey} alt="" />
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 'bold' }}
                          ml={1}
                        >
                          {t('verification_progress')}
                        </Typography>
                      </Box>
                    )}
                    {status === 'Proved' && (
                      <Box className={s.flexStart}>
                        <img src={verifiedIconGreen} alt="" />
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 'bold' }}
                          ml={1}
                        >
                          {t('verified_by_prover')}
                        </Typography>
                        <Box ml={2}>
                          <ReportVerifyProver id={nft.id} />
                        </Box>
                      </Box>
                    )}
                    {status === 'NotProved' && (
                      <Box className={s.flexStart}>
                        <img src={verifiedIconRed} alt="" />
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 'bold' }}
                          ml={1}
                        >
                          {t('not_verified_by_prover')}
                        </Typography>
                        <Box ml={2}>
                          <ReportVerifyProver id={nft.id} />
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box mt={5} className={s.boxSubmitBtn}>
                    <Button
                      variant="outlined"
                      // disabled={
                      //   isSubmitting ||
                      //   (values.name === nft.name &&
                      //     values.description === nft.description &&
                      //     !mediaFileObject.file &&
                      //     !coverObject.file)
                      // }
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      {t('save')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </CustomContainer>
      <EditTokenSuccessDialog collectionId={collectionId} tokenId={id} />
      <EditTokenErrorDialog />
    </React.Fragment>
  );
};

export default observer(CreateNft);
